import React from "react";
import '../../components/Layout/layout.css';

const h5 = ({ children }) => {
  return (
    <h5 className="h1">{children}</h5>
  );
};

export default h5;
